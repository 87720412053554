import React from 'react';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { InfoCard } from '@backstage/core-components';

const StyledInfoCard = styled(InfoCard)({
  padding: '20px',
});

const StyledButtonContainer = styled(Grid)({
  padding: '12px 0',
});

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  }
}));

const HomePageInfoBox = () => {
  return (
    <StyledInfoCard title="Welcome to our Novo Nordisk Developer Portal!">
      <p>Our Developer Portal, powered by Backstage, is the ultimate hub for developers, offering a unified space for easy resource discovery, seamless collaboration, and efficient code sharing.</p>
      <h3>Highlights of the Developer Portal</h3>
      <ul>
        <li>
          <h4>Streamlined Collaboration:</h4>
          <p>Our Backstage-powered portal fosters seamless collaboration and efficient code sharing.</p>
        </li>
        <li>
          <h4>Enhanced Productivity:</h4>
          <p>From junior to senior developers, our portal elevates workflow and productivity.</p>
        </li>
        <li>
          <h4>Key Services:</h4>
          <p>Prioritized services include Software Catalog, Software Templates and Plugins (Golden Paths), TechDocs, and Tech Radar.</p>
        </li>
      </ul>
      <StyledButtonContainer container item xs={12} justifyContent="center" alignItems="center" direction="column">
        <StyledButton
          variant="contained"
          href="../create/templates/default/developer-portal-onboarding"
        >
          Developer Portal onboarding
        </StyledButton>
      </StyledButtonContainer>
    </StyledInfoCard>
  );
};

export default HomePageInfoBox;