import React from 'react';
import { Route } from 'react-router-dom';

import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { AlertDisplay, OAuthRequestDialog, SignInPage, SignInProviderConfig } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FeatureFlagged, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { UnifiedThemeProvider } from '@backstage/theme';
import { shortcutsPlugin } from '@backstage-community/plugin-shortcuts';

import LightIcon from '@mui/icons-material/WbSunny';
import { MidgardOnboard, SystemInfo } from '@mexl/plugin-envinfo';

import { novoTheme } from './utils/themes/novoTheme';
import HomePage from './components/home/HomePage';
import FaqPage from './components/faq/FaqPage';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import { apis } from './apis';
import { SettingsPage } from './components/settings/SettingsPage';
import { CustomCatalogPage } from './components/catalog/CustomCatalogPage/catalog/CustomCatalogPage'
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';


const microsoftProvider: SignInProviderConfig = {
  id: 'microsoft-auth-provider',
  title: 'Microsoft',
  message: 'Sign In using Microsoft Azure AD',
  apiRef: microsoftAuthApiRef,
}

const app = createApp({
  apis,
  themes: [
    {
      id: 'novo-theme-1',
      title: 'Novo Nordisk Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={novoTheme} children={children} />
      ),
    }
  ],
  components: {
    SignInPage: props => {
      return (
        <SignInPage
          {...props}
          auto
          provider={microsoftProvider}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  featureFlags: [
    {
      pluginId: '',
      name: 'show-faq-page',
      description: 'Enables the FAQ page',
    },
    {
      pluginId: '',
      name: 'show-shortcuts-plugin',
      description: 'Enables the Shortcuts plugin',
    },
    {
      pluginId: '',
      name: 'show-novoaccess-card',
      description: 'Shows NovoAccess card on the Entity Page',
    },
  ],
  plugins: [shortcutsPlugin],
});

const routes = (
  <FlatRoutes>
    {/* SIDEBAR NAV START */}
    <Route
      path="/"
      element={<HomePage />}
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route
      path="/catalog"
      element={<CatalogIndexPage />}
    >
      <CustomCatalogPage
        key="catalog"
        pagination
      />
    </Route>
    <Route
      path="/software-templates"
      element={<CatalogIndexPage />}
    >
      <CustomCatalogPage
        initialKind="template"
        key="software-templates"
        pageTitleSuffix=" - Templates"
        hideKindFilter
        pagination
      />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={<ScaffolderPage />}
    />
    <Route
      path="/api-docs"
      element={<ApiExplorerPage />}
    >
      <CustomCatalogPage
        initialKind="api"
        key="api-docs"
        pageTitleSuffix=" - APIs"
        hideKindFilter
        pagination
      />
    </Route>
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route
      path="/docs"
      element={<TechDocsIndexPage />}
    />
    <FeatureFlagged with="show-faq-page">
      <Route path="/faq" element={<FaqPage />} />
    </FeatureFlagged>
    <Route
      path="/settings"
      element={<SettingsPage />}
    />
    {/* SIDEBAR NAV END */}

    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route
      path="/catalog-graph"
      element={<CatalogGraphPage />}
    />
    <Route
      path="/midgard-onboard/:url"
      element={<MidgardOnboard />}
    />
    <Route 
      path="/devtools" 
      element={<DevToolsPage />} 
    >
      {customDevToolsPage}
    </Route>
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />;
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>
        <SystemInfo />
        
        {routes}
        
      </Root>
    </AppRouter>
  </>,
);
