// originates from https://github.com/backstage/backstage/blob/bef0282748e86328f54404f91da4aa32e4377b19/plugins/catalog/src/components/CatalogPage/DefaultCatalogPage.tsx

import {
    Content,
    CreateButton,
    PageWithHeader,
    SupportButton,
    TableColumn,
    TableProps,
  } from '@backstage/core-components';
  import { configApiRef, useApi } from '@backstage/core-plugin-api';
  import {
    EntityListProvider,
    UserListFilterKind,
    EntityOwnerPickerProps
  } from '@backstage/plugin-catalog-react';
  import React, { ReactNode, useState } from 'react';
  import { catalogTranslationRef } from './translation';
  import { useTranslationRef } from '@backstage/core-plugin-api/alpha';
  
  import { CatalogTable, CatalogTableColumnsFunc, CatalogTableRow } from '@backstage/plugin-catalog';
  import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
  import { usePermission } from '@backstage/plugin-permission-react';

import { Collapse, FormControlLabel, Grid, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CustomFilters } from '../catalog-react/CustomFilters';
import { EntityKindPicker } from '../catalog-react/EntityKindPicker/EntityKindPicker';
import { UserListPicker } from '../catalog-react/UserListPicker/UserListPicker';
import { EntityTypePicker } from '../catalog-react/EntityTypePicker/EntityTypePicker';
  
/** @internal */
export type BaseCatalogPageProps = {
  filters: ReactNode;
  content?: ReactNode;
  initialKind?: string;
  pagination?:
    | boolean
    | { mode?: 'cursor'; limit?: number }
    | { mode: 'offset'; limit?: number; offset?: number };
  pageTitleSuffix: string;
  hideKindFilter?: boolean;
  initiallySelectedFilter?: UserListFilterKind;
};

const LeftItemsBox = styled(Grid)({
  display: 'flex',
  alignItems: 'flex-end',
  flexWrap: 'wrap',
  minWidth: 0,
  overflow: 'visible',
});

const RightItemsBox = styled(Grid)(({ theme }) => ({
  flex: '0 1 auto',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  marginLeft: theme.spacing(1),
  minWidth: 0,
  overflow: 'visible',
}));

const ShowMoreFilters = styled(FormControlLabel)({
  margin: '12px',
});

/** @internal */
export function BaseCatalogPage(props: BaseCatalogPageProps) {
  const {
    filters,
    content = <CatalogTable />,
    pagination,
    initialKind,
    pageTitleSuffix,
    hideKindFilter,
    initiallySelectedFilter = 'all'
  } = props;

  const orgName =
    useApi(configApiRef).getOptionalString('organization.name') ?? 'Backstage';
  const createComponentLink = (() => "/create"); // Workaround for missing "Create" button
  const { t } = useTranslationRef(catalogTranslationRef);
  const { allowed } = usePermission({
    permission: catalogEntityCreatePermission,
  });

  const [showMoreFilters, setShowMoreFilters] = useState(false);

  const handleMoreFiltersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowMoreFilters(event.target.checked);
  };

  return (
    <PageWithHeader title={t('indexPage.title', { orgName, pageTitleSuffix })} themeId="home">
      <Content>
        <EntityListProvider pagination={pagination}>
          <Grid container>
            <LeftItemsBox item xs>
              <UserListPicker initialFilter={initiallySelectedFilter}/>
              <EntityKindPicker initialFilter={initialKind} hidden={hideKindFilter} />
              <EntityTypePicker />
              <ShowMoreFilters
                label="Show more filters" 
                control={
                  <Switch
                    checked={showMoreFilters}
                    onChange={handleMoreFiltersChange}
                    name="moreFilters"
                  />
                }
              />
            </LeftItemsBox>
            <RightItemsBox item>
              {allowed && (
                <CreateButton
                  title={t('indexPage.createButtonTitle')}
                  to={createComponentLink && createComponentLink()}
                />
              )}
              <SupportButton>{t('indexPage.supportButtonContent')}</SupportButton>
            </RightItemsBox>
          </Grid>
          <Grid>
            <Collapse in={showMoreFilters}>
              {filters}
            </Collapse>
          </Grid>
          <Grid pt={3}> 
            {content}
          </Grid>
        </EntityListProvider>
      </Content>
    </PageWithHeader>
  );
}

/**
 * Props for root catalog pages.
 *
 * @public
 */
export interface DefaultCatalogPageProps {
  initiallySelectedFilter?: UserListFilterKind;
  columns?: TableColumn<CatalogTableRow>[] | CatalogTableColumnsFunc;
  actions?: TableProps<CatalogTableRow>['actions'];
  initialKind?: string;
  tableOptions?: TableProps<CatalogTableRow>['options'];
  emptyContent?: ReactNode;
  ownerPickerMode?: EntityOwnerPickerProps['mode'];
  pagination?:
    | boolean
    | { mode?: 'cursor'; limit?: number }
    | { mode: 'offset'; limit?: number; offset?: number };
  filters?: ReactNode;
  pageTitleSuffix?: string;
  hideKindFilter?: boolean;
}

export function CustomCatalogPage(props: DefaultCatalogPageProps) {
  const {
    columns,
    actions,
    initialKind = 'component',
    tableOptions = {},
    emptyContent,
    pagination,
    ownerPickerMode,
    filters,
    pageTitleSuffix = '',
    hideKindFilter = false,
  } = props;

  return (
    <BaseCatalogPage
      initialKind={initialKind}
      pageTitleSuffix={pageTitleSuffix}
      hideKindFilter={hideKindFilter}
      filters={
        filters ?? <CustomFilters ownerPickerMode={ownerPickerMode} />
      }
      content={
        <CatalogTable
          columns={columns}
          actions={actions}
          tableOptions={tableOptions}
          emptyContent={emptyContent}
        />
      }
      pagination={pagination}
    />
  );
}