import { useState, useEffect } from 'react';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const SystemInfoContainer = styled('div')({
  position: 'fixed',
  top: 4,
  right: 42,
  zIndex: 1000,
  display: 'flex',
  borderRadius: '20px',
  overflow: 'hidden',
  boxShadow:
    '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  transform: 'perspective(600px) rotateX(10deg)',
});

const Status = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '5px 10px',
  fontSize: '12px',
  fontWeight: 'bold',
  letterSpacing: '1px',
  color: theme.palette.secondary.main,
  cursor: 'pointer',
  userSelect: 'none',
}));

const Url = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  padding: '5px 10px',
  fontSize: '12px',
  fontWeight: 'bold',
  letterSpacing: '1px',
  cursor: 'pointer',
  userSelect: 'none',
}));

const DialogContentContainer = styled(DialogContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  marginTop: '15px',
}));

const DialogActionsContainer = styled(DialogActions)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: '15px',
}));

const CloseButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
    backgroundColor:theme.palette.primary.dark,
  },
}));

const DialogTitleContainer = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

function changeFavicon(env: string): void {
  const faviconNodes: any = document.querySelectorAll("link[rel*='icon']");
  faviconNodes.forEach((faviconNode : any) => {
    faviconNode.href = faviconNode.href.replace("favicon.ico", "favicon-"+env+".ico");
    faviconNode.href = faviconNode.href.replace("favicon-16x16.png", "favicon-"+env+".ico");
    faviconNode.href = faviconNode.href.replace("favicon-32x32.png", "favicon-"+env+".ico");
  });
}

export const SystemInfo = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<{ url: string } | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [strPath, setStrPath] = useState('');
  const [environments, setEnvironments] = useState({});
  const [envConfig, setEnvConfig] = useState<
    Array<{ id: string; title: string; contains: string; information: string }>
  >([]);
  const configApi = useApi(configApiRef);
  const [appInfo, setAppInfo] = useState<{
    title: string;
    baseUrl: string;
    appVersion: string;
  } | null>(null);

  useEffect(() => {
    fetch(
      `${configApi.getOptionalString('backend.baseUrl')}/api/envinfo/version`,
    )
      .then(response => response.json())
      .then(info => {
        setAppInfo(info);
      })
      .catch(error => {
        console.error('Failed to fetch app information:', error);
      });
  }, [configApi]);

  useEffect(() => {
    fetch(
      `${configApi.getOptionalString('backend.baseUrl')}/api/envinfo/envconfig`,
    )
      .then(response => response.json())
      .then(config => {
        setEnvConfig(config);
      })
      .catch(error => {
        console.error('Failed to fetch environment configuration:', error);
      });
  }, [configApi]);

  useEffect(() => {
    fetch(`${configApi.getOptionalString('backend.baseUrl')}/api/envinfo/host`)
      .then(response => response.json())
      .then(data => {
        setData(data);
        setLoading(false);

        for (const config of envConfig) {
          if (data.url.includes(config.contains)) {
            setStrPath(config.title);
            setEnvironments(config.information);
            if(['local', 'test', 'dev'].includes(config.id)) {
              changeFavicon(config.id);
            }
            return;
          }
        }
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [configApi, envConfig]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    console.log('Closing dialog');
    setOpen(false);
  };

  if (loading) {
    return <div>Loading... {data?.url}</div>;
  }

  if (error) {
    console.log('envinfo error', error);
    
    return (
      <SystemInfoContainer>
        <Status>EnvInfo error</Status>
      </SystemInfoContainer>
      )
  }

  return strPath.length >= 4 ? (
    <SystemInfoContainer onClick={handleClickOpen}>
      <Status>{appInfo?.appVersion}</Status>
      <Url>Backstage : {strPath}</Url>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitleContainer>
          Backstage {strPath} Environment {appInfo?.appVersion}
        </DialogTitleContainer>
        <DialogContentContainer>
          <DialogContentText
            dangerouslySetInnerHTML={{ __html: environments }}
          />
        </DialogContentContainer>
        <DialogActionsContainer>
          <CloseButton
            onClick={event => handleClose(event)}
            variant="contained"
            color="primary"
          >
            Close
          </CloseButton>
        </DialogActionsContainer>
      </Dialog>
    </SystemInfoContainer>
  ) : null;
};

export default SystemInfo;
