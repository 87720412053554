import React from 'react';

import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import LockIcon from '@mui/icons-material/Lock';
import ChatIcon from '@mui/icons-material/Chat';
import BuildIcon from '@mui/icons-material/Build';
import HelpIcon from '@mui/icons-material/Help';
import SchoolIcon from '@mui/icons-material/School'
import GitHubIcon from '@mui/icons-material/GitHub'; 
import TextFieldsIcon from '@mui/icons-material/TextFields';
import WebIcon from '@mui/icons-material/Web';
import CloudIcon from '@mui/icons-material/Cloud';
import EngineeringIcon from '@mui/icons-material/Engineering';

export const developerPortalLinks = [
  {
    url: 'https://novonordisk.sharepoint.com/sites/DevX',
    label: 'DevX Scalability Program',
    icon: <HomeIcon />
  },
  {
    url: 'https://backstage.io/docs/overview/what-is-backstage/',
    label: 'Backstage Official TechDocs',
    icon: <DescriptionIcon />
  },
  {
    url: 'https://github.com/innersource-nn',
    label: 'GitHub Inner Source',
    icon: <GitHubIcon />
  },
  {
    url: 'https://novonordisk.sharepoint.com/sites/DevX/SitePages/Developer-platform.aspx',
    label: 'Developer Portal Concept',
    icon: <WebIcon />
  },
  {
    url: 'https://status.heimdall.novonordisk.cloud/',
    label: 'Heimdall Status Page',
    icon: <CloudIcon />
  },
  {
    url: 'https://midgard.heimdall.novonordisk.cloud/docs/default/component/product-engineering-docs/',
    label: 'Product Engineering Guide',
    icon: <EngineeringIcon />
  },
];

export const novoNordiskLinks = [
  {
    url: 'https://novoaccess.corp.novocorp.net/identityiq/accessRequest/accessRequest.jsf#/accessRequest',
    label: 'novoAccess',
    icon: <LockIcon />
  },
  {
    url: 'https://chatgpt.novo-genai.com/',
    label: 'NN ChatGPT',
    icon: <ChatIcon />
  },
  {
    url: 'https://novonordisk.service-now.com/it',
    label: 'ServiceNow',
    icon: <BuildIcon />
  },
  {
    url: 'https://novonordisk.service-now.com/hrportal?id=hr_index',
    label: 'askHR',
    icon: <HelpIcon />
  },
  {
    url: 'https://novonordisk.sharepoint.com/sites/ISOtrain',
    label: 'ISOTrain',
    icon: <SchoolIcon />
  },
  {
    url: 'https://novonordisk.sharepoint.com/sites/Abbreviations',
    label: 'NN Acronyms',
    icon: <TextFieldsIcon />,
  },
];
